import './styles/aui.page.reset';
import './styles/aui.page.typography';
import './styles/aui.page.iconography';
import './styles/aui.page.links';
import './styles/aui.pattern.button';
import './styles/aui.pattern.icon';
import './styles/aui.pattern.nav'; // for the basic aui-nav pattern only
import '@atlassian/aui/src/less/aui-header.less';
import '@atlassian/aui/src/less/adg-header-quicksearch.less';
import '@atlassian/aui/src/less/adg-header-buttons.less';
import '@atlassian/brand-logos/dist/aui-legacy/aui-header.css'; // todo: remove in AUI 9.
export {};
