import $ from './jquery';
import { getMessageLogger } from './internal/deprecation';
import { recomputeStyle } from './internal/animation';
import globalize from './internal/globalize';

var overflowEl;
var _hiddenByAui = [];

/**
 * Dims the screen using a blanket div
 * @param useShim deprecated, it is calculated by dim() now
 */
function dim (useShim, zIndex) {

    //if we're blanketing the page it means we want to hide the whatever is under the blanket from the screen readers as well
    function hasAriaHidden(element) {
        return element.hasAttribute('aria-hidden');
    }

    function isAuiLayer(element) {
        return element.classList.contains('aui-layer');
    }

    Array.prototype.forEach.call(document.body.children, function (element) {
        if (!hasAriaHidden(element) && !isAuiLayer(element)) {
            element.setAttribute('aria-hidden', 'true');
            _hiddenByAui.push(element);
        }
    });

    if (!overflowEl) {
        overflowEl = document.body;
    }

    if (useShim === true) {
        useShimDeprecationLogger();
    }

    var isBlanketShowing = (!!dim.$dim) && dim.$dim.attr('aria-hidden') === 'false';

    if (!!dim.$dim) {
        dim.$dim.remove();
        dim.$dim = null;
    }

    dim.$dim = $('<div></div>').addClass('aui-blanket');
    dim.$dim.attr('tabindex', '0'); //required, or the last element's focusout event will go to the browser
    dim.$dim.appendTo(document.body);

    if (!isBlanketShowing) {
        //recompute after insertion and before setting aria-hidden=false to ensure we calculate a difference in
        //computed styles
        recomputeStyle(dim.$dim);

        dim.cachedOverflow = {
            overflow: overflowEl.style.overflow,
            overflowX: overflowEl.style.overflowX,
            overflowY: overflowEl.style.overflowY
        };

        overflowEl.style.overflowX = 'hidden';
        overflowEl.style.overflowY = 'hidden';
        overflowEl.style.overflow = 'hidden';
    }

    dim.$dim.attr('aria-hidden', 'false');

    if (zIndex) {
        dim.$dim.css({zIndex: zIndex});
    }

    return dim.$dim;
}

/**
 * Removes semitransparent DIV
 * @see dim
 */
function undim () {
    _hiddenByAui.forEach(function (element) {
        element.removeAttribute('aria-hidden');
    });

    _hiddenByAui = [];

    if (dim.$dim) {
        dim.$dim.attr('aria-hidden', 'true');

        if (overflowEl) {
            overflowEl.style.overflow = dim.cachedOverflow.overflow;
            overflowEl.style.overflowX = dim.cachedOverflow.overflowX;
            overflowEl.style.overflowY = dim.cachedOverflow.overflowY;
        }
    }
}

var useShimDeprecationLogger = getMessageLogger('useShim', {
    extraInfo: 'useShim has no alternative as it is now calculated by dim().'
});

globalize('dim', dim);
globalize('undim', undim);

export {
    dim,
    undim
};
