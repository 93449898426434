/**
 * @fileOverview
 * Includes the most minimal set of JavaScript
 * possible to make an Atlassian UI "work".
 *
 * This entry-point should *not* include anything that causes
 * a global side-effect. The only exception to this rule
 * is registering a global variable or AMD module.
 */
export * from './behaviours/aui.behaviour.format';
export * from './behaviours/aui.behaviour.version';
export * from './behaviours/aui.behaviour.event-bus';
export * from './behaviours/aui.behaviour.escape-html';
