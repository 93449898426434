import skate from './internal/skate';
import skateTemplateHtml from 'skatejs-template-html';
import enforce from './internal/enforcer';
import { INPUT_SUFFIX } from './internal/constants';

function getLabel(element) {
    return element.querySelector('label');
}

function updateLabelFor(element, change) {
    if (element.hasAttribute('for')) {
        getLabel(element).setAttribute('for', `${change.newValue}${INPUT_SUFFIX}`);
    } else {
        getLabel(element).removeAttribute('for');
    }
}

function updateLabelForm(element, change) {
    if (element.hasAttribute('form')) {
        getLabel(element).setAttribute('form', change.newValue);
    } else {
        getLabel(element).removeAttribute('form');
    }
}

const LabelEl = skate('aui-label', {
    template: skateTemplateHtml('<label><content></content></label>'),
    created: function (element) {
        element._label = getLabel(element);    // required for quick access from test
    },
    attached: function (element) {
        enforce(element).attributeExists('for');
    },
    attributes: {
        'for': updateLabelFor,
        form: updateLabelForm
    },
    prototype: {
        get disabled() {
            return this.hasAttribute('disabled');
        },
        set disabled(value) {
            if (value) {
                this.setAttribute('disabled', '');
            } else {
                this.removeAttribute('disabled');
            }
        }
    },
    events: {
        click: function (element, e) {
            if (element.disabled) {
                e.preventDefault();
            }
        }
    }
});

export default LabelEl;
