import $ from '../jquery';

const eventRoot = document || {};
const $eventTarget = $(eventRoot);

/**
 * Triggers a custom event on the AJS object
 *
 * @param {String} name - name of event
 * @param {Array} args - args for event handler
 */
function triggerEvt (name, args) {
    $eventTarget.trigger(name, args);
}

/**
 * Binds handler to the AJS object
 *
 * @param {String} name
 * @param {Function} func
 */
function bindEvt (name, func) {
    $eventTarget.bind(name, func);
}

/**
 * Some generic error handling that fires event in multiple contexts
 * - on AJS object
 * - on Instance
 * - on AJS object with prefixed id.
 *
 * @param evt
 * @param inst
 * @param args
 */
function triggerEvtForInst (evt, inst, args) {
    $(inst).trigger(evt, args);
    triggerEvt(evt, args);
    if (inst.id) {
        triggerEvt(inst.id + '-' + evt, args);
    }
}

export {
    bindEvt,
    triggerEvt,
    triggerEvtForInst
};
