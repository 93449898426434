import globalize from './internal/globalize';

var uniqueID;
var uniqueIDstring;
var uniqueIDcounter = 0;

/**
 * Generate a unique ID string, checking the ID is not present in the DOM before
 * returning. Note uniqueID, uniqueIDstring, uniqueIDcounter = 0; set at top of
 * file.
 *
 * @param {String} prefix String to prepend to ID instead of default AUI prefix.
 *
 * @returns {String}
 */
function generateUniqueId (prefix) {
    uniqueID = uniqueIDcounter++ + '';
    uniqueIDstring = prefix ? prefix + uniqueID : 'aui-uid-' + uniqueID;

    if (!document.getElementById(uniqueIDstring)) {
        return uniqueIDstring;
    } else {
        uniqueIDstring = uniqueIDstring + '-' + new Date().getTime();

        if (!document.getElementById(uniqueIDstring)) {
            return uniqueIDstring;
        } else {
            throw new Error('Timestamped fallback ID "' + uniqueIDstring + '" exists.');
        }
    }
}

globalize('id', generateUniqueId);

export default generateUniqueId;
