import $ from './jquery';
import '@atlassian/tipsy/src/javascripts/jquery.tipsy';

const AUI_TOOLTIP_CLASS_NAME = 'aui-tooltip';

function handleStringOption ($self, stringOption) {
    // Pass string values straight to tipsy
    $self.tipsy(stringOption);
    return $self;
}

function bindTooltip ($self, options) {
    $self.tipsy(options);

    const triggerIsHover = options.trigger === 'hover' || !options.trigger && $self.tipsy.defaults.trigger === 'hover';
    const hideOnClick = options && options.hideOnClick && triggerIsHover;
    if (hideOnClick) {
        const onClick = function() {
            $(this).tipsy('hide');
        };

        if (options.live) {
            $self.on('click.tipsy', options.live, onClick);
        } else {
            $self.on('click.tipsy', onClick);
        }
    }
    return $self;
}

function getTipsyObject (el) {
    return $(el).data('tipsy');
}

/**
 * If a given selector has been initialised as a tooltip, returns the tooltip element.
 * Otherwise, returns an empty jQuery object so calls are still chainable yet benign.
 * @param el
 * @returns {jQuery}
 */
function maybeTooltip (el) {
    const tipsy = getTipsyObject(el);
    if (tipsy) {
        return tipsy.$tip;
    } else {
        return $();
    }
}

function findAllTooltips () {
    return $(document.getElementsByClassName(AUI_TOOLTIP_CLASS_NAME));
}

$.fn.tooltip = function (options) {
    const allOptions = $.extend({}, $.fn.tooltip.defaults, typeof options === 'string' ? {} : options);

    // Always add AUI's tooltip class name to tipsies created by AUI.
    const { className } = allOptions;
    if (typeof className === 'string') {
        allOptions.className = `${AUI_TOOLTIP_CLASS_NAME} ${className}`
    } else if (typeof className === 'function') {
        allOptions.className = (...args) => `${AUI_TOOLTIP_CLASS_NAME} ${className.apply(undefined, args)}`;
    } else {
        allOptions.className = AUI_TOOLTIP_CLASS_NAME;
    }

    function init() {
        const $this = $(this);
        if (typeof options === 'string') {
            handleStringOption($this, options);
        } else {
            bindTooltip($this, allOptions);
        }
        return $this;
    }

    // Handle live option
    if (allOptions.live) {
        return init.call(this);
    }

    // If not live, bind each object in the collection
    return this.each(init);
};

$.fn.tooltip.defaults = {
    opacity: 1.0,
    offset: 1,
    delayIn: 500,
    hoverable: true,
    hideOnClick: true,
    aria: true
};

export default $;
export {
    findAllTooltips,
    maybeTooltip
};
