import $ from './jquery';
import * as logger from './internal/log';
import globalize from './internal/globalize';

/**
 * Adds functions to the list of methods to be run asynchronously after DomContentLoaded.
 *
 * Wraps error handling around the provided function so its failure won't prevent
 * other init functions running.
 *
 * @param {Function} func Function to be called on initialisation.
 *
 * @return {Object}
 */
function toInit (func) {
    $.when($.ready).then(function () {
        // Ensure that every function passed through toInit happens asynchronously after DomContentLoaded events,
        // and will be executed in its own stack, meaning that the UI can be updated in-between expensive handlers.
        setTimeout(function () {
            try {
                func.call(this, $);
            } catch (ex) {
                logger.error('Failed to run init function: ' + ex.message, func, ex, ex.stack);
            }
        }, 1);
    });

    return this;
}

globalize('toInit', toInit);

export default toInit;
