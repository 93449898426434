import format from './format';
import globalize from './internal/globalize';
import keys from './internal/i18n/aui';

/**
 * Returns the value defined in AJS.I18n.keys for the given key. If AJS.I18n.keys does not exist, or if the given key does not exist,
 * the key is returned - this could occur in plugin mode if the I18n transform is not performed;
 * or in flatpack mode if the i18n JS file is not loaded.
 */
export var I18n = {
    keys: keys,
    getText: function (key, ...params) {
        if (Object.prototype.hasOwnProperty.call(this.keys, key)) {
            return format.apply(null, [this.keys[key]].concat(params));
        }

        return key;
    }
};

/**
 * Deprecated - do not use AJS.I18n.getText from global scope within AUI code. Use import { I18n } from '../i18n'
 * Also keep in mind that WRM is looking for patterns like "I18n.getText" so do not create
 * aliases for I18n or export it from here using 'default'. Webpack transforms it then to
 * 'd.default.getText' and it won't match.
 **/
globalize('I18n', I18n);
