import $ from './jquery';
import globalize from './internal/globalize';
import params from './params';

function populateParameters (parameters) {
    if (!parameters) {
        parameters = params;
    }

    $('.parameters input').each(function () {
        var value = this.value;
        var id = this.title || this.id;

        if ($(this).hasClass('list')) {
            if (parameters[id]) {
                parameters[id].push(value);
            } else {
                parameters[id] = [value];
            }
        } else {
            parameters[id] = (value.match(/^(tru|fals)e$/i) ? value.toLowerCase() === 'true' : value);
        }
    });
}

globalize('populateParameters', populateParameters);

export default populateParameters;
