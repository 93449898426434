import skate from 'skatejs';
import jQuery from 'jquery';

/**
 * Allows us to add a new DOM element for rendering ADG styled checkbox glyphs,
 * so we can get our desired aesthetic without having to rely on a specific markup pattern.
 */
skate('checkbox', {
    type: skate.type.CLASSNAME,
    attached: function(el) {
        jQuery('input[type=checkbox]', el).each(function(i, checkbox) {
            jQuery('<span class="aui-form-glyph"></span>').insertAfter(checkbox);
        });
    },
    detached: function(el) {
        jQuery('.aui-form-glyph', el).remove();
    }
});
