import $ from './jquery';
import globalize from './internal/globalize';

/**
 * Shortcut function to see if passed element is truncated/clipped, eg. with
 * text-overflow: ellipsis.
 *
 * @param {String | Element | jQuery} element The element to check.
 *
 * @returns {Boolean}
 */
function isClipped (el) {
    el = $(el);
    return (el.prop('scrollWidth') > el.prop('clientWidth'));
}

globalize('isClipped', isClipped);

export default isClipped;
