import createHeader from './internal/header/create-header';
import skate from './internal/skate';

const Header = skate('aui-header', {
    type: skate.type.CLASSNAME,
    created: function (element) {
        createHeader(element);
    }
});

export default Header;
