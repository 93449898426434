import $ from './jquery';
import { recomputeStyle } from './internal/animation';
import amdify from './internal/amdify';
import globalize from './internal/globalize';

var ID_BANNER_CONTAINER = 'header';

function banner (options) {
    var $banner = renderBannerElement(options);

    pruneBannerContainer();
    insertBanner($banner);

    return $banner[0];
}

function renderBannerElement({body, type = 'error'}) {
    return $('<div class="aui-banner" role="banner"></div>')
        .append($.parseHTML(body || ''))
        .addClass(`aui-banner-${type}`);
}

function pruneBannerContainer() {
    var $container = findContainer();
    var $allBanners = $container.find('.aui-banner');

    $allBanners.get().forEach(function (banner) {
        var isBannerAriaHidden = banner.getAttribute('aria-hidden') === 'true';
        if (isBannerAriaHidden) {
            $(banner).remove();
        }
    });
}

function findContainer() {
    return $('#' + ID_BANNER_CONTAINER);
}

function insertBanner($banner) {
    var $bannerContainer = findContainer();
    if (!$bannerContainer.length) {
        throw new Error('You must implement the application header');
    }

    $banner.prependTo($bannerContainer);
    recomputeStyle($banner);
    $banner.attr('aria-hidden', 'false');
}

amdify('aui/banner', banner);
globalize('banner', banner);
export default banner;
