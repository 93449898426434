import './../spinner';
import classNames from './class-names';

export const spinner = '<aui-spinner size="small"></aui-spinner>';
const SPINNER_SELECTOR = 'aui-spinner';

/**
 * Appends spinner to .aui-restfultable-status container if not already present
 *
 * @param $el
 */
export function appendStatusSpinner($el) {
    if ($el.find(SPINNER_SELECTOR).length === 0) {
        $el.find(`.${classNames.STATUS}`).append(spinner);
    }
}

/**
 * Removes spinner from .aui-restfultable-status container
 *
 * @param $el
 */
export function removeStatusSpinner($el) {
    $el.find(`.${classNames.STATUS} ${SPINNER_SELECTOR}`).remove();
}
