import $ from '../jquery';

const DELAY = 40;

// an element can be detached and then attached multiple times
// in some situations -- e.g., when re-appending an element to
// another place in the DOM.
export function ifGone (element) {
    let deferred = new $.Deferred();
    if (element) {
        setTimeout(() => {
            if (!element.parentElement) {
                deferred.resolve();
            }
        }, DELAY);
    } else {
        deferred.reject();
    }
    return deferred.promise();
}
