
function state (element) {
    return {
        /**
         * sets an internal state on a component element
         * @param element the element to which the state will be added
         * @param stateName the name of the state
         * @param stateValue the value that the state will be changed to
         */
        set: function (stateName, stateValue) {
            if (element._state === undefined) {
                element._state = {};
            }

            element._state[stateName] = stateValue;
        },

        /**
         * gets an internal state on a component element
         * @param element the element to which the state will be added
         * @param stateName the name of the state
         */
        get: function (stateName) {
            if (element._state) {
                return element._state[stateName];
            }
        }
    };
}

export default state;
