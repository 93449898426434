/**
 * Like el.hasAttribute(attr) but designed for use within a skate attribute
 * change handler where you only have access to change.oldValue.
 */
export function computeBooleanValue(attrValue) {
    return attrValue !== null;
}

export function setBooleanAttribute(el, attr, newValue) {
    if (newValue) {
        el.setAttribute(attr, '');
    } else {
        el.removeAttribute(attr);
    }
}

export function computeEnumValue(enumOptions, value) {
    const matchesEnumValue = enumValue => enumValue.toLowerCase() === value.toLowerCase();

    const isMissing = value === null;
    const isInvalid = !isMissing && !enumOptions.values.filter(matchesEnumValue).length;

    if (isMissing) {
        if (enumOptions.hasOwnProperty('missingDefault')) {
            return enumOptions.missingDefault;
        }
        return null;
    }

    if (isInvalid) {
        if (enumOptions.hasOwnProperty('invalidDefault')) {
            return enumOptions.invalidDefault;
        } else if (enumOptions.hasOwnProperty('missingDefault')) {
            return enumOptions.missingDefault;
        }
        return null;
    }

    return enumOptions.values.length ? enumOptions.values.filter(matchesEnumValue)[0] : null;
}


export function setEnumAttribute(el, enumOptions, newValue) {
    el.setAttribute(enumOptions.attribute, newValue);
}

/**
 * Helper functions useful for implementing reflected boolean and enumerated
 * attributes and properties.
 *
 * @see https://html.spec.whatwg.org/multipage/infrastructure.html#reflecting-content-attributes-in-idl-attributes
 * @see https://html.spec.whatwg.org/multipage/infrastructure.html#boolean-attribute
 * @see https://html.spec.whatwg.org/multipage/infrastructure.html#enumerated-attribute
 */
export default {
    computeBooleanValue,
    setBooleanAttribute,

    computeEnumValue,
    setEnumAttribute
};
