import * as logger from './internal/log';
import amdify from './internal/amdify';
import skate from './internal/skate';
import SpinnerEl, {SIZE} from './spinner';

function isBusy (button) {
    return button.hasAttribute('aria-busy') && button.getAttribute('aria-busy') === 'true';
}

function isInputNode (button) {
    return button.nodeName === 'INPUT';
}

skate('aui-button', {
    type: skate.type.CLASSNAME,
    created: function (element) {
        element._spinner = new SpinnerEl();
        element._spinner.setAttribute('size', SIZE.SMALL.name);
    },
    prototype: {
        /**
         * Adds a spinner to the button and hides the text
         *
         * @returns {HTMLElement}
         */
        busy: function () {
            if (isInputNode(this) || isBusy(this)) {
                logger.warn('It is not valid to call busy() on an input button.');
                return this;
            }

            this.appendChild(this._spinner);
            this.setAttribute('aria-busy', true);
            this.setAttribute('busy', '');

            return this;
        },

        /**
         * Removes the spinner and shows the tick on the button
         *
         * @returns {HTMLElement}
         */
        idle: function () {
            if (isInputNode(this) || !isBusy(this)) {
                logger.warn('It is not valid to call idle() on an input button.');
                return this;
            }

            this.removeChild(this._spinner);
            this.removeAttribute('aria-busy');
            this.removeAttribute('busy');

            return this;
        },

        /**
         * Removes the spinner and shows the tick on the button
         *
         * @returns {Boolean}
         */
        isBusy: function () {
            if (isInputNode(this)) {
                logger.warn('It is not valid to call isBusy() on an input button.');
                return false;
            }

            return isBusy(this);
        }
    }
});

amdify('aui/button');
