// Global setup that used to be in the main AJS namespace file.
import $ from './jquery';
import globalize from './internal/globalize';
import populateParameters from './populate-parameters';
import version from './version';

// Set the version.
$(function () {
    var $body = $('body');

    if (!$body.data('auiVersion')) {
        $body.attr('data-aui-version', version);
    }

    populateParameters();
});

// Setting Traditional to handle our default param serialisation.
// See http://api.jquery.com/jQuery.param/ for more information.
$.ajaxSettings.traditional = true;
globalize('$', $);
export default $;
