import $ from './jquery';
import globalize from './internal/globalize';

/**
 * Clones the element specified by the selector and removes the id attribute.
 *
 * @param {String} selector A jQuery selector
 */
function clone (selector) {
    return $(selector).clone().removeAttr('id');
}

globalize('clone', clone);

export default clone;
