import $ from '../jquery';
import amdify from '../internal/amdify';
import format from '../format'; // eslint-disable-line no-unused-vars
import { I18n } from '../i18n';
import validatorRegister from './validator-register';

//Input length
function minMaxLength(field) {
    var fieldValueLength = field.el.value.length;
    let fieldIsEmpty = fieldValueLength === 0;
    var minlength = parseInt(field.args('minlength'), 10);
    var maxlength = parseInt(field.args('maxlength'), 10);

    if (minlength && maxlength && minlength === maxlength && (!fieldIsEmpty && fieldValueLength !== minlength)) {
        const exactlengthMessage = makeMessage('exactlength', field.args, [minlength]);
        field.invalidate(exactlengthMessage);
    } else if (minlength && (fieldValueLength < minlength && !fieldIsEmpty)) {
        const minlengthMessage = makeMessage('minlength', field.args);
        field.invalidate(minlengthMessage);
    } else if (maxlength && (fieldValueLength > maxlength)) {
        const maxlengthMessage = makeMessage('maxlength', field.args);
        field.invalidate(maxlengthMessage);
    } else {
        field.validate();
    }
}
validatorRegister.register(['maxlength', 'minlength'], minMaxLength); //AUI-prefixed attribute is deprecated as of 5.9.0
validatorRegister.register('[maxlength],[minlength]', minMaxLength);

//Field matching
validatorRegister.register(['matchingfield'], function (field) {
    var thisFieldValue = field.el.value;
    var matchingField = document.getElementById(field.args('matchingfield'));
    var matchingFieldValue = matchingField.value;

    var matchingFieldMessage = makeMessage('matchingfield', field.args, [thisFieldValue, matchingFieldValue]);

    var shouldHidePasswords = isPasswordField(field.el) || isPasswordField(matchingField);
    if (shouldHidePasswords) {
        matchingFieldMessage = makeMessage('matchingfield-novalue', field.args);
    }

    if (!thisFieldValue || !matchingFieldValue){
        field.validate();
    } else if (matchingFieldValue !== thisFieldValue) {
        field.invalidate(matchingFieldMessage);
    } else {
        field.validate();
    }
});

function isPasswordField(field) {
    return field.getAttribute('type') === 'password';
}

//Banned words
validatorRegister.register(['doesnotcontain'], function (field) {
    var doesNotContainMessage = makeMessage('doesnotcontain', field.args);

    if (field.el.value.indexOf(field.args('doesnotcontain')) === -1) {
        field.validate();
    } else {
        field.invalidate(doesNotContainMessage);
    }
});

//Matches regex

function matchesRegex(val, regex){
    var matches = val.match(regex);
    if (!matches) {
        return false;
    }
    var isExactMatch = (val === matches[0]);
    return isExactMatch;
}

function pattern(field) {
    var patternMessage = makeMessage('pattern', field.args);

    if (matchesRegex(field.el.value, new RegExp(field.args('pattern')))) {
        field.validate();
    } else {
        field.invalidate(patternMessage);
    }
}

validatorRegister.register(['pattern'], pattern); //AUI-prefixed attribute is deprecated as of 5.9.0
validatorRegister.register('[pattern]', pattern);

//Native Required
function required(field) {
    var requiredMessage = makeMessage('required', field.args);
    if (field.el.value) {
        field.validate();
    } else {
        field.invalidate(requiredMessage);
    }
}
validatorRegister.register(['required'], required); //AUI-prefixed attribute is deprecated as of 5.9.0
validatorRegister.register('[required]', required);

//Field value range (between min and max)

function minOrMax(field) {
    var validNumberMessage = makeMessage('validnumber', field.args);

    var fieldValue = parseInt(field.el.value, 10);
    if (isNaN(fieldValue)) {
        field.invalidate(validNumberMessage);
        return;
    }

    var minValue = field.args('min');
    var maxValue = field.args('max');

    if (minValue && (fieldValue < parseInt(minValue, 10))) {
        field.invalidate(makeMessage('min', field.args));
    } else if (maxValue && (fieldValue > parseInt(maxValue, 10))){
        field.invalidate(makeMessage('max', field.args));
    } else {
        field.validate();
    }
}
validatorRegister.register(['min', 'max'], minOrMax); //AUI-prefixed attribute is deprecated as of 5.9.0
validatorRegister.register('[min],[max]', minOrMax);

//Date format
validatorRegister.register(['dateformat'], function (field) {
    var dateFormatSymbolic = field.args('dateformat');
    var dateFormatMessage = makeMessage('dateformat', field.args);

    var symbolRegexMap = {
        'Y': '[0-9]{4}',
        'y': '[0-9]{2}',
        'm': '(0?[1-9]|10|11|12)',
        'M': '[Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec]',
        'D': '[Mon|Tue|Wed|Thu|Fri|Sat|Sun]',
        'd': '([0-2]?[1-9]|10|20|30|31)'
    };

    var dateFormatSymbolArray = dateFormatSymbolic.split('');
    var dateFormatRegexString = '';

    dateFormatSymbolArray.forEach(function (dateSymbol) {
        var isRecognisedSymbol = symbolRegexMap.hasOwnProperty(dateSymbol);
        if (isRecognisedSymbol) {
            dateFormatRegexString += symbolRegexMap[dateSymbol];
        } else {
            dateFormatRegexString += dateSymbol;
        }
    });

    var dateFormatRegex = new RegExp(dateFormatRegexString + '$', 'i');
    var isValidDate = matchesRegex(field.el.value, dateFormatRegex);

    if (isValidDate) {
        field.validate();
    } else {
        field.invalidate(dateFormatMessage);
    }
});

//Checkbox count
validatorRegister.register(['minchecked', 'maxchecked'], function (field) {
    var amountChecked = $(field.el).find(':checked').length;
    var aboveMin = !field.args('minchecked') || (amountChecked >= field.args('minchecked'));
    var belowMax = !field.args('maxchecked') || (amountChecked <= field.args('maxchecked'));

    var belowMinMessage = makeMessage('minchecked', field.args);
    var aboveMaxMessage = makeMessage('maxchecked', field.args);

    if (aboveMin && belowMax) {
        field.validate();
    } else if (!aboveMin) {
        field.invalidate(belowMinMessage);
    } else if (!belowMax) {
        field.invalidate(aboveMaxMessage);
    }
});

/*
     Retrieves a message for a plugin validator through the data attributes or the default (which is in the i18n file)
 */
function makeMessage(key, accessorFunction, customTokens) {
    var inFlatpackMode = I18n.keys !== undefined;
    var defaultMessage;
    if (inFlatpackMode) {
        defaultMessage = I18n.keys['aui.validation.message.' + key];
    } else {
        defaultMessage = pluginI18nMessages[key];
    }

    var messageTokens = customTokens;
    if (!customTokens) {
        messageTokens = [accessorFunction(key)];
    }

    var customMessageUnformatted = accessorFunction(key + '-msg');
    var formattingArguments;

    if (customMessageUnformatted) {
        formattingArguments = [customMessageUnformatted].concat(messageTokens);
    } else {
        formattingArguments = [defaultMessage].concat(messageTokens);
    }

    return AJS.format.apply(null, formattingArguments);
}

/*
 The value I18n.getText('aui.validation.message...') (defaultMessage) cannot be refactored as it
 must appear verbatim for the plugin I18n transformation to pick it up
 */
var pluginI18nMessages = {
    minlength: I18n.getText('aui.validation.message.minlength'),
    maxlength: I18n.getText('aui.validation.message.maxlength'),
    exactlength: I18n.getText('aui.validation.message.exactlength'),
    matchingfield: I18n.getText('aui.validation.message.matchingfield'),
    'matchingfield-novalue': I18n.getText('aui.validation.message.matchingfield-novalue'),
    doesnotcontain: I18n.getText('aui.validation.message.doesnotcontain'),
    pattern: I18n.getText('aui.validation.message.pattern'),
    required: I18n.getText('aui.validation.message.required'),
    validnumber: I18n.getText('aui.validation.message.validnumber'),
    min: I18n.getText('aui.validation.message.min'),
    max: I18n.getText('aui.validation.message.max'),
    dateformat: I18n.getText('aui.validation.message.dateformat'),
    minchecked: I18n.getText('aui.validation.message.minchecked'),
    maxchecked: I18n.getText('aui.validation.message.maxchecked')
};

amdify('aui/form-validation/basic-validators');
