import './styles/aui.page.reset';
import './styles/aui.page.typography';
import './styles/aui.page.iconography';
import './styles/aui.page.links';
import './styles/aui.pattern.page-header'; // for sidebar titles
import './styles/aui.pattern.badge'; // for sidebar item notifications (sidebar contextually overrides them)
import './styles/aui.pattern.button';
import './styles/aui.pattern.icon';
import './styles/aui.pattern.nav'; // for vertical nav
import './aui.component.nav'; // for navigation expand/collapse behaviour
import './aui.component.inline-dialog2'; // for collapsed navigation menus :(
import './aui.component.tooltip'; // for collapsed navigation items
import '@atlassian/aui/src/less/aui-sidebar.less';
export { default as sidebar } from '@atlassian/aui/src/js/aui/sidebar';
