import $ from '../jquery';

function replaceAttributeForID($clone, id, value, attribute) {
    $clone.find(`[${attribute}]`).attr(attribute, (i, oldValue) => id === oldValue ? value : undefined);
}

function setCorrespondingTriggersID($clone, id, uuid) {
    replaceAttributeForID($clone, id, uuid, 'aria-controls');
    replaceAttributeForID($clone, id, uuid, 'aria-owns');
}

export default function deduplicateIDs($clone, uidFunction) {
    let wrapper = $('<div></div>');
    wrapper.append($clone);
    wrapper.find('[id]')
        .each((i, el) => {
            const uuid = uidFunction(`${el.id}-`);
            setCorrespondingTriggersID(wrapper, el.id, uuid);
            el.id = uuid;
        });
}
