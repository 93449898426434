
export default function (name, fn) {
    if (window.define) {
        var alias = window.define;
        alias(name, [], function () {
            return fn;
        });
    }
    return fn;
}
