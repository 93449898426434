/**
 * @fileOverview
 * This imports everything that should be available and executed
 * if a consumer requests the "core" of AUI (i.e., the "ajs" web-resource).
 * It should be kept as lean as possible!
 * Only import things that are used across 90% of pages.
 */
import './styles/aui.page.reset';
import './styles/aui.page.typography';
import './styles/aui.page.iconography';
import './styles/aui.page.links';
import './styles/aui.pattern.basic';
import './styles/aui.pattern.page-layout';
import './styles/aui.pattern.page-header';
import './styles/aui.pattern.group';
import './styles/aui.pattern.avatar';
import './styles/aui.pattern.button';
import './styles/aui.pattern.forms';
import './styles/aui.pattern.toolbar2';
export * from './aui.core';
// todo AUI-4814: undo the weird relationships between layer, layerManager, and LayerManager.global
export * from './behaviours/aui.behaviour.layer-manager'; // because this is created in layer.js, and is needed as a side-effect on most pages :(
export {default as $} from '@atlassian/aui/src/js/aui/setup';
export {default as toInit} from '@atlassian/aui/src/js/aui/to-init';
