// HTML + CSS patterns
import './styles/aui.pattern.avatar'
import './styles/aui.pattern.badge'
import './styles/aui.pattern.banner'
import './styles/aui.pattern.basic'
import './styles/aui.pattern.button'
import './styles/aui.pattern.forms'
import './styles/aui.pattern.group'
import './styles/aui.pattern.help'
import './styles/aui.pattern.icon'
import './styles/aui.pattern.inline-dialog'
import './styles/aui.pattern.label'
import './styles/aui.pattern.lozenge'
import './styles/aui.pattern.messages'
import './styles/aui.pattern.multi-step-progress'
import './styles/aui.pattern.nav'
import './styles/aui.pattern.page-header'
import './styles/aui.pattern.page-layout'
import './styles/aui.pattern.table'
import './styles/aui.pattern.tabs'
import './styles/aui.pattern.toolbar2'
