import $ from '../jquery';
import globalize from './globalize';
import uniqueId from '../unique-id';

/**
 * Apply a unique ID to the element. Preserves ID if the element already has one.
 *
 * @param {Element} el Selector to find target element.
 * @param {string} [prefix] Optional. String to prepend to ID instead of default AUI prefix.
 *
 * @returns {undefined}
 */
function addId (el, prefix = '') {
    var element = $(el);
    var addprefix = prefix || false;

    element.each(function () {
        var $el = $(this);

        if (!$el.attr('id')) {
            $el.attr('id', uniqueId(addprefix));
        }
    });
}

globalize('_addID', addId);

export default addId;
