import $ from './jquery';
import globalize from './internal/globalize';
import './forms/custom-checkbox';
import './forms/custom-radio';

/**
 * Enables the specified form element.
 *
 * @param {Element} el The element to enable.
 * @param {Boolean} b The flag setting enabled / disabled.
 *
 * @returns {jQuery}
 */
function enable (el, b) {
    var $el = $(el);

    if (typeof b === 'undefined') {
        b = true;
    }

    return $el.each(function () {
        this.disabled = !b;
    });
}


/**
 * Forms: Inline Help - toggles visibility of inline help content.
 *
 * @method inlineHelp
 * @namespace AJS
 * @for AJS
 */
function inlineHelp () {
    $(document).on('click', '.icon-inline-help', function () {
        var $t = $(this).siblings('.field-help');
        if ($t.hasClass('hidden')){
            $t.removeClass('hidden');
        } else {
            $t.addClass('hidden');
        }
    });
}

globalize('enable', enable);
globalize('inlineHelp', inlineHelp);

export {
    enable,
    inlineHelp
};
