import skate from '../skate';
import escapeHtml from '../../escape-html';

export default skate('aui-option', {
    created: function (element) {
        Object.defineProperty(element, 'value', {
            get: function () {
                return element.getAttribute('value') || escapeHtml(this.textContent);
            },
            set: function (value) {
                element.setAttribute('value', value);
            }
        });
    },
    prototype: {
        serialize: function () {
            var json = {};
            if (this.hasAttribute('img-src')) {
                json['img-src'] = escapeHtml(this.getAttribute('img-src'));
            }
            json.value = this.value;
            json.label = escapeHtml(this.textContent);

            return json;
        }
    }
});
