export default {
    // AJS
    REORDER_SUCCESS: 'RestfulTable.reorderSuccess',
    ROW_ADDED: 'RestfulTable.rowAdded',
    ROW_REMOVED: 'RestfulTable.rowRemoved',
    EDIT_ROW: 'RestfulTable.switchedToEditMode',
    SERVER_ERROR: 'RestfulTable.serverError',

    // Backbone
    CREATED: 'created',
    UPDATED: 'updated',
    FOCUS: 'focus',
    BLUR: 'blur',
    SUBMIT: 'submit',
    SAVE: 'save',
    MODAL: 'modal',
    MODELESS: 'modeless',
    CANCEL: 'cancel',
    CONTENT_REFRESHED: 'contentRefreshed',
    RENDER: 'render',
    FINISHED_EDITING: 'finishedEditing',
    VALIDATION_ERROR: 'validationError',
    SUBMIT_STARTED: 'submitStarted',
    SUBMIT_FINISHED: 'submitFinished',
    INITIALIZED: 'initialized',
    ROW_INITIALIZED: 'rowInitialized',
    ROW_EDIT: 'editRow'
};
