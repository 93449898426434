export default {
    NO_VALUE: 'aui-restfultable-editable-no-value',
    NO_ENTRIES: 'aui-restfultable-no-entires',
    RESTFUL_TABLE: 'aui-restfultable',
    ROW: 'aui-restfultable-row',
    READ_ONLY: 'aui-restfultable-readonly',
    ACTIVE: 'aui-restfultable-active',
    ALLOW_HOVER: 'aui-restfultable-allowhover',
    FOCUSED: 'aui-restfultable-focused',
    MOVEABLE: 'aui-restfultable-movable',
    DISABLED: 'aui-restfultable-disabled',
    SUBMIT: 'aui-restfultable-submit',
    CANCEL: 'aui-restfultable-cancel',
    EDIT_ROW: 'aui-restfultable-editrow',
    CREATE: 'aui-restfultable-create',
    DRAG_HANDLE: 'aui-restfultable-draghandle',
    ORDER: 'aui-restfultable-order',
    EDITABLE: 'aui-restfultable-editable',
    ERROR: 'error',
    DELETE: 'aui-restfultable-delete',
    STATUS: 'aui-restfultable-status'
};
