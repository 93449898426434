(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define("@atlassian/aui", ["jquery"], factory);
	else if(typeof exports === 'object')
		exports["@atlassian/aui"] = factory(require("jquery"));
	else
		root["AJS"] = factory(root["jQuery"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__5__) {
return 