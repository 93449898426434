import $ from './jquery';
import * as logger from './internal/log';
import globalize from './internal/globalize';

const EMPTY_SELECTOR = false;
const $target = $(window);

/**
 * Binds events to the window object. See jQuery bind documentation for more
 * details. Exceptions are caught and logged.
 */
function bind (eventType, eventData, handler) {
    try {
        if (typeof handler === 'function') {
            return $target.on(eventType, EMPTY_SELECTOR, eventData, handler);
        } else {
            return $target.on(eventType, EMPTY_SELECTOR, eventData);
        }
    } catch (e) {
        logger.log('error while binding: ', e.message);
    }
}

/**
 * Unbinds event handlers from the window object. See jQuery unbind
 * documentation for more details. Exceptions are caught and logged.
 */
function unbind (eventType, handler) {
    try {
        return $target.off(eventType, handler);
    } catch (e) {
        logger.log('error while unbinding: ', e.message);
    }
}

/**
 * Triggers events on the window object. See jQuery trigger documentation for
 * more details. Exceptions are caught and logged.
 */
function trigger (eventType, extraParameters) {
    try {
        return $target.trigger(eventType, extraParameters);
    } catch (e) {
        logger.log('error while triggering: ' + e.message);
    }
}

globalize('bind', bind);
globalize('trigger', trigger);
globalize('unbind', unbind);

export {
    bind,
    unbind,
    trigger
};
