import globalize from './globalize';

function polyfillConsole (prop) {
    return function () {
        if (typeof console !== 'undefined' && console[prop]) {
            Function.prototype.apply.call(console[prop], console, arguments);
        }
    };
}

var log = polyfillConsole('log');
var warn = polyfillConsole('warn');
var error = polyfillConsole('error');

globalize('error', error);
globalize('log', log);
globalize('warn', warn);

export {
    log,
    warn,
    error
};
