import * as logger from './log'

function enforcer (element) {

    function attributeExists (attributeName) {
        var errorMessage = attributeName + ' wasn\'t defined';

        return satisfiesRules(function () {
            return element.hasAttribute(attributeName)
        }, errorMessage);
    }

    function refersTo (attributeName) {

        if (!attributeExists(attributeName, element)){
            return false;
        }

        var desiredId = element.getAttribute(attributeName);
        var errorMessage = 'an element with id set to "' + desiredId  + '" was not found';

        return satisfiesRules(function () {
            return document.getElementById(desiredId);
        }, errorMessage);
    }

    function ariaControls() {
        return refersTo('aria-controls');
    }

    function ariaOwns() {
        return refersTo('aria-owns');
    }

    function satisfiesRules (predicate, message) {
        if (!predicate()) {
            if (element) {
                logger.error(message, element);
            } else {
                logger.error(message);
            }
            return false;
        }
        return true;
    }

    return {
        attributeExists: attributeExists,
        refersTo: refersTo,
        satisfiesRules: satisfiesRules,
        ariaControls: ariaControls,
        ariaOwns: ariaOwns
    }
}

export default enforcer;
